<template>
  <v-container>
      <v-row>
        <v-col cols="7">
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
           class="mt-3"
            dense
            v-model="days"
            :items="periods"
            item-text="text"
            item-value="value"

            @change="init"
          ></v-select>
        </v-col>
        <v-col cols="2" align="center" v-if="badmin" >
          <v-checkbox v-model="with_me" @click="init" label="inkl. me"></v-checkbox>
        </v-col>
        <v-col cols="1" align="right">
           <v-btn class="mt-3"  @click="init" icon><v-icon>mdi-reload</v-icon></v-btn>
        </v-col>

      </v-row>
      
    <v-row class="my-4">
      <v-col v-for="box in boxes" :key="box.key" md="3" sm="6">
          <v-card flat color="primary" class="white--text rounded-lg">
            <v-card-title>{{ box.label }}</v-card-title>
            <v-card-text class="text-xs-h1 white--text">
              <v-row>
                <v-col align="center"><v-icon class="white--text">mdi-lan-connect</v-icon></v-col>
                <v-col align="center"><v-icon class="white--text">mdi-account-box</v-icon></v-col>
              </v-row>
                <v-row class="white--text">
                <v-col align="center"><span style="font-size:24px">{{ data[box.key].total }}</span></v-col>
                <v-col align="center">
                   <v-tooltip bottom open-delay="500" v-if="badmin && data[box.key].unique.length > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" style="cursor:default;font-size:24px"
        v-on="on">{{ data[box.key].unique.length }}</span>
                    </template>
                    <ul class="list" :class="{ 'tt-content' : data[box.key].unique.length > 20}" v-html="data[box.key].unique.join('')"></ul>
                  </v-tooltip>
                  <span v-else style="cursor:default;font-size:24px">{{ data[box.key].unique.length }}</span>

                  </v-col>
              </v-row>
             
            </v-card-text>
        </v-card>
      </v-col>

    </v-row>


    <v-data-table v-if="badmin" dense :items="logs" :headers="headers" :items-per-page="25" class="mb-16">

   <template v-slot:[`item.route`]="{ item }" >
        <span v-html="getRoute(item.method, item.route)"></span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ d2s(item.created_at, "short") }}
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <v-icon small @click="lookup(item.user_id, item.created_at.split(' ')[0])">mdi-timeline-text-outline</v-icon>
      </template>

    </v-data-table>
      <v-bottom-sheet v-model="showBottomSheet" hide-overlay scrollable>
        <div class="bottom-sheet">
          <v-row class="mt-2 ma-2">
            <h3>{{ details.username }} - {{ details.date }}</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="showBottomSheet = false" class="white--text">mdi-close</v-icon>
          </v-row>
        <ul style="padding:.75rem;margin:0;font-family:Courier;font-size:12px">
          <li v-for="item in details.data" :key="item.id" style="display:block"> {{ item }}</li>  
        </ul>
        </div>
    </v-bottom-sheet>
  </v-container>

</template>

<script>
import { d2s } from "@/utils/dates";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
export default {
  data() {
    return {
      showBottomSheet: false,
      days: "0",
      with_me: true,
      data: {
        meta: null,
        logs: [],
        requests:{total: null, unique: []},
        logins:{total: null, unique: []},       
        budget:{total: null, unique: []},       
        saves:{total: null, unique: []},      
        downloads:{total: null, unique: []},
        explore:{total: null, unique: []},        
        progress:{total: null, unique: []},
        history:{total: null, unique: []},
      },
      details: {username: null, date: null, data: null},
      badmin: localStorage.badmin && localStorage.badmin == 'true',
      meta: {
        tenants: [],
        divisions: [],
        users: [],
        forecasts: [],
        snapshots: []
      },
      logs: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "User ID", value: "user_id" },
        { text: "Name", value: "username" },
        { text: "letzter Request", value: "route" },
        { text: "Client App Version", value: "build" },
        { text: "Datum", value: "created_at" },
        { text: "", value: "details" },
      ],
      boxes: [
        {label: "Requests", key: "requests"},
        {label: "Logins", key: "logins"},
        {label: "Budget-Übersicht", key: "budget"},
        {label: "Budget speichern", key: "saves"},
        {label: "Downloads", key: "downloads"},
        {label: "FC-Explorer", key: "explore"},
        {label: "FC-Entwicklung", key: "progress"},
        {label: "Timeline", key: "history"}
      ],
      periods: [
        {text: "Heute", value: "0"},
        {text: "Gestern", value: "1"},
        {text: "Vorgestern", value: "2"},
        {text: "letzte 3 Tage", value: "0,3"},
        {text: "letzte 7 Tage", value: "0,7"},
        {text: "letzte 14 Tage", value: "0,14"},
        {text: "letzte 30 Tage", value: "0,30"},
        {text: "letzte 90 Tage", value: "0,90"}
      ]
    };
  },
  computed: {
    ...mapGetters(["me","current", "divisions"]),
  },
  methods: {
    d2s,
    async init() {
      const data = await this.$store.dispatch("req", { route: "stats?days=" + this.days + '&with_me=' + this.with_me });
      this.meta = data.meta;
      this.logs = data.logs;
      this.logs.map(item => item.username = this.meta.users.find(u => u.id === item.user_id).username);

      this.data = {
        requests: this.getStats(data.requests),
        logins: this.getStats(data.logins),
        budget: this.getStats(data.budget),
        saves: this.getStats(data.saves),
        downloads: this.getStats(data.downloads),
        explore: this.getStats(data.explore),
        progress: this.getStats(data.progress),
        history: this.getStats(data.history),
      };
    },
    getRoute(method, route) {
        let str;
        let path = route.substr(5).split('?')[0];
        let details = {
            tenant: null,
            fcid: null,
            division: null,
            snapshot: null
        }
       


        if (method === 'POST' && path === "auth") {
            return "Logout";
        }

        const urlParams = new URLSearchParams(route.substr(5).split('?').pop());

        if (urlParams.has("tenant")) {
            let query_tenant = this.meta.tenants.find(x => x.id == urlParams.get("tenant"));
            if (query_tenant) details.tenant = query_tenant.name;
        }

        if (urlParams.has("fcid")) {
            
            let query_fcid = this.meta.forecasts.find(x => x.id == urlParams.get("fcid"));
            if (query_fcid) {
                details.fcid = query_fcid.title;

                if (!details.tenant) {
                    let subquery_tenant = this.meta.tenants.find(x => x.id == query_fcid.tenant_id);
                    if (subquery_tenant) details.tenant = subquery_tenant.name;
                }

            }
        }

         if (urlParams.has("division")) {
            let query_division = this.meta.divisions.find(x => x.id == urlParams.get("division"));
            if (query_division) details.division = query_division.name;
        }

         if (urlParams.has("snapshot")) {
            let query_snapshot = this.meta.snapshots.find(x => x.id == urlParams.get("snapshot"));
            if (query_snapshot) details.snapshot = "Snapshot " + query_snapshot.title;
        }


        if (path === "budget") {

            if (method === "GET") {
                str = "";
            } else {
                str = method;
            }

            str+= " <strong>Budgetübersicht</strong> ";

            let args = [];

            if (details.tenant) args.push(details.tenant)
            if (details.division) args.push(details.division);
            if (details.fcid) args.push(details.fcid);
            if (details.snapshot) args.push(details.snapshot);

            str+=args.join(" &raquo; ");
            return str;
        }

        if (path === "progress") {
            str = "<strong>Gesamt-Entwicklung</strong>";
            if (details.tenant) str+= " " + details.tenant + " ";
            return str;
        }

        if (path === "explore") {
          str = "<strong>FC Explorer</strong>";

          if (details.tenant) {
              str+= " " + details.tenant + " ";
              urlParams.delete("tenant");
          }
          if (details.fcid) {
            str+= " " + details.fcid + " ";
            urlParams.delete("fcid");
          }

           if (details.snapshot) {
            str+= details.snapshot + " ";
            urlParams.delete("snapshot");
          }

          str+= "{query: " + urlParams.toString().replace(/%2C/g, ",") + "}"

          return str;
        }


        return '<span class="method_' + method.toLowerCase() + '">' + method + '</span> ' + route;
    },
    getStats(arr) {

        let users = [];
        arr.forEach(item => {
          if (users.indexOf(item) > -1) return;
          users.push(item);
        })

      return {
        total: arr.length,
        unique: users.map(u => `<li>${this.meta.users.find(m => m.id == u).username}</li>`).sort()
      }
     
    },
    async lookup(){
      let u = arguments[0];
      let d = arguments[1];
      const res = await this.$store.dispatch("req", { route: "stats/detail?uid=" + u + '&date=' + d });

      res.forEach (item => {
        item.created_at = DateTime.fromSQL(item.created_at, { zone: process.env.VUE_APP_SQL_TZ })
                                  .setZone(this.me.timezone)
                                  .toFormat('yyyy-MM-dd HH:mm:ss')
                                  
                            
      })
     
      this.details = {
        username: this.meta.users.find(m => m.id == u).username,
        date: d2s(d, 'dateshort'),
        data: res
      }
      this.showBottomSheet = true;
    }
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">

.headline-link{
  display: inline-block;
  margin-left:0.35em;
  color: inherit;
  text-decoration:none;
}

.tt-content{
  padding:1rem 1.5rem;
  column-count: 4;
}

.list{
  display:block;
  margin:0 !important;
  padding:0 !important;
  font-size:14px;
 

  li{
  display:block;
  padding:0.25em 0.1em;
  // border-bottom:1px solid rgba(255,255,255,.33);

    &:last-child{
      border-bottom: none;
    }
}
}

.method_get{
  font-weight: bold;
  color: mediumslateblue;
}

.method_post{
  font-weight: bold;
  color: #579b67;
}

.bottom-sheet{
  background-color: rgba(0,0,0, .85);
  color:#f5f5f5;
  padding:1rem;
  padding-bottom:2rem;

  ul{
    margin:0;
    padding:0;
    max-height:70vh;
    overflow-y: auto;
  }
}

</style>